import React from 'react';
import { useAuth } from '../AuthContext';
import { auth } from '../firebase';
import { useNavigate, useLocation } from 'react-router-dom';

const SignOut = () => {  
    const { setCurrentUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const handleSignOut = async () => {

        try {
            await auth.signOut();
            setCurrentUser(null);

            // Call the backend to clear the Django session
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/signout/`, {
                method: 'POST',  // or 'GET' if you prefer
                credentials: 'include',  // Ensure cookies (session ID) are sent
            });

            // Redirect back to the current page after sign out
            const redirectTo = location.pathname; // Current path
            const redirectSearch = location.search; // Preserve any query params

            // Redirect to the home page if the user is currently on the account page
            if (redirectTo === '/account') {
                navigate('/'); // Redirect to home page
            } else {
                navigate(`${redirectTo}${redirectSearch}`); // Redirect back to the current page
            }
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <p onClick={handleSignOut} style={{ cursor: 'pointer' }}>
            Sign Out
        </p>
    );
};
export default SignOut;