import React from 'react';
import { TextField } from '@mui/material';

const FormField = ({ type, name, onChange, value, placeholder }) => {
  return (
    <TextField
      type={type}
      name={name}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      sx={{ m: 1, borderRadius: '4px' }}
    />
  );
};

export default FormField;
