
export const VALID_PRODUCTS = [
    'Ace with Mocks',
    'Burnout to Break Free',
  ];
  
  export const VALID_PRODUCT_TYPES = [
    'Full Mock Interview',
    'Crash Mock Interview',
    'Full Hour Discussion',
    'Half Hour Discussion',
    'Short Discussion',
  ];
  
//   export const VALID_COMPANIES = [
//     'Amazon',
//     'Google',
//     // Add other valid companies here
//   ];
  
//   export const VALID_ROLES = [
//     'Product Manager',
//     'Software Engineer',
//     // Add other valid roles here
//   ];