import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography, TextField, IconButton, Button, Tooltip, Alert, CircularProgress, Snackbar } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { FiEdit2, FiCheck } from 'react-icons/fi';
import ClearIcon from '@mui/icons-material/Clear';
// import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

const Account = () => {
  // const { currentUser } = useAuth();
  const [selectedTab, setSelectedTab] = useState(0);
  const [subTab, setSubTab] = useState(0); // For managing sub-tabs under Sessions
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');  
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Fetch user email from backend
  const fetchUserInfo = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/user/details/`, {
        method: 'GET',
        credentials: 'include',  // Include credentials to send cookies
      });
      if (response.ok) {
        const data = await response.json();
        setEmail(data.email);
        setFirstName(data.first_name || '');
        setLastName(data.last_name || '');  
      } else {
        console.error('Failed to fetch user info:', await response.json());
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    } finally {
      setLoading(false); // Stop loading after fetching data
    }
  };

  useEffect(() => {
    fetchUserInfo(); // Call the function to fetch user info on component mount
  }, []);


  useEffect(() => {
    if (showDeleteMessage) {
      // Auto-dismiss the message after 5 seconds
      const timer = setTimeout(() => {
        setShowDeleteMessage(false);
      }, 5000);
      
      return () => clearTimeout(timer); // Clear timer if component unmounts
    }
  }, [showDeleteMessage]);


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSubTabChange = (event, newValue) => {
    setSubTab(newValue);
  };

  const makeFieldEditable = () => {
    setIsEditingName(true);
  };

  const cancelName = () => {
    setIsEditingName(false);
    fetchUserInfo();
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/user/update/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
        }),
      });
      if (response.ok) {
        setSuccessMessage('Update successful.');
        setIsEditingName(false);
        setTimeout(() => setSuccessMessage(''), 3000); // Clear the message after 3 seconds
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.error || 'Update failed.');
        setTimeout(() => setErrorMessage(''), 3000); // Clear the message after 3 seconds
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred.');
      setTimeout(() => setErrorMessage(''), 3000); // Clear the message after 3 seconds
      console.error('Error updating name:', error);
    }
  };

  const handleDeleteAccount = () => {
    setShowDeleteMessage(true);
  };

  if (loading) {  
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </div>;
  }


  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {/* Left pane for navigation */}
      <Box 
        sx={{ 
          width: '25%', 
          bgcolor: '#f5f5f5', 
          color: '#747474',
          pt: 5, 
          position: 'relative', 
          top: 0, 
        }}  
      >
        <Tabs
          orientation="vertical"
          value={selectedTab}
          onChange={handleTabChange}
          sx={{ 
            pl: 0,  
            alignItems: 'flex-start', 
            textAlign: 'left',
            '.MuiTabs-indicator': {
              backgroundColor: 'transparent', 
            },
          }}
        >
          <Tab 
            label="Profile" 
            sx={{ 
              textTransform: 'none', 
              fontSize: '1rem',
              pl: 0,
              justifyContent: 'flex-start',
              width: '100%',
              '&.Mui-selected': {
                bgcolor: '#e0e0e0',  
                color: '#5c5c5c',    
                borderRight: '4px solid #5c5c5c', 
                borderRadius: 1,
              },
              '&:hover': {
                bgcolor: '#eeeeee',  
              },
            }} 
          />
          <Tab 
            label="Sessions" 
            sx={{ 
              textTransform: 'none', 
              fontSize: '1rem',
              justifyContent: 'flex-start',
              width: '100%',
              '&.Mui-selected': {
                bgcolor: '#e0e0e0',  
                color: '#5c5c5c',    
                borderRight: '4px solid #5c5c5c', 
                borderRadius: 1,
              },
              '&:hover': {
                bgcolor: '#eeeeee',  
              },
            }} 
          />
        </Tabs>
      </Box>

      {/* Right pane for content */}
      <Box sx={{ width: '80%', p: 3 }}>
        {selectedTab === 0 && (
          // Profile Tab Content
          <Box>
            {/* Container for Name and Email */}
            <Box 
              sx={{ 
                border: '1px solid #f5f5f5',   
                borderRadius: 1,               
                pl: 10,
                pr: 10,
                pt: 5,
                pb: 5,                    
                width: '80%',
                marginTop: -3,
                marginLeft: -2,                   
                display: 'flex', 
                flexDirection: 'column',
                gap: 2,                       
                mb: 6                         
              }}
            >
              {/* Name Section */}
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  ml: -2,
                  justifyContent: 'flex-start'
                }}
              >
                <Typography 
                  sx={{ 
                    width: '30%', 
                  }}
                >
                  Name:
                </Typography>
                <TextField
                  fullWidth
                  disabled={!isEditingName}
                  placeholder="First name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  sx={{ 
                    ml: -15,
                    mr: 1, 
                    width: '37%' 
                  }}
                />
                <TextField
                  fullWidth
                  disabled={!isEditingName}
                  placeholder="Last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  sx={{ 
                    mr: 1, 
                    width: '37%' 
                  }}
                />
                {!isEditingName ? (
                  <IconButton
                    onClick={makeFieldEditable}
                    sx={{
                      color: 'grey',
                      backgroundColor: '#f5f5f5',
                      '&:hover': { bgcolor: '#e0e0e0' },
                      borderRadius: '50%',
                      ml: 1,
                    }}
                  >
                    <FiEdit2 />
                  </IconButton>
                ) : (
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      gap: 4, 
                      ml: 0 
                    }}>
                    <IconButton
                      onClick={cancelName}
                      sx={{
                        color: 'grey',
                        backgroundColor: '#f5f5f5',
                        '&:hover': { bgcolor: '#e0e0e0' },
                        borderRadius: '50%',
                        ml: 1,
                        mr: -2
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleUpdate}
                      sx={{
                        color: '#fff',
                        backgroundColor: '#1890ff',
                        borderRadius: '50%',
                        '&:hover': { bgcolor: '#1071e5' },
                        ml: 0,
                        mr: -4
                      }}
                    >
                      <FiCheck />
                    </IconButton>
                    </Box>
                )}
              </Box>

              {/* Email Section */}
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  ml: -2, 
                }}
              >
                <Typography 
                  sx={{ 
                    width: '30%' 
                  }}
                >
                  Email:
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  placeholder="Email"
                  value={email || ''} 
                  sx={{ 
                    ml: -15,
                    mr: 1, 
                    width: '75%' 
                  }}
                />
                <Tooltip 
                  title={
                    <>
                      <div>To update your email, please contact support@eastbadge.com.</div>
                      <br />
                      <div>In-app email update is planned for release in H1 2025.</div>
                    </>
                  }
                  arrow
                >
                  <Box sx={{ display: 'flex', ml: 1 }}>
                    <IconButton>
                      <InfoOutlinedIcon 
                        sx={{ 
                          color: '#bdbdbd',
                          '&:hover': { borderRadius: '100%' },
                        }} 
                      />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Box>
            </Box>

            {/* Delete Account Button */}
            <Button
              variant="outlined"
              color="error"
              onClick={handleDeleteAccount}
              sx={{ 
                mt: 0,
                ml: -2, 
              }}
            >
              Delete Account
            </Button>
            {/* Inline Message for Account Deletion */}
            {showDeleteMessage && (
              <Box 
                sx={{ 
                  mt: 2,
                  ml: -2,
                  width: '100%',
                }}
              >
                <Alert 
                  severity="info"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => setShowDeleteMessage(false)}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Please contact support@eastbadge.com for this action. In-app account deletion is planned for release in H1 2025.
                </Alert>
              </Box>
            )}
          </Box>
        )}

        {selectedTab === 1 && (
          // Sessions Tab Content
          <Box 
            sx={{ 
              border: '1px solid #f5f5f5',   
              borderRadius: 1,               
              pl: 10,
              pr: 10,
              pt: 5,
              pb: 5,                    
              width: '80%',
              marginTop: -3,
              marginLeft: -2,                   
              display: 'flex', 
              flexDirection: 'column',
              gap: 2,                       
              mb: 6                          
            }}
          >
            <Tabs 
              value={subTab} 
              onChange={handleSubTabChange} 
              sx={{
                borderBottom: 1, 
                borderColor: 'divider', 
                '.MuiTabs-indicator': { 
                  backgroundColor: 'transparent' 
                }
              }}
            >
              <Tab 
                label="Upcoming" 
                sx={{ 
                  textTransform: 'none', 
                  fontSize: '1rem',
                  pl: 0,
                  pr: 0,
                  justifyContent: 'flex-start',
                  width: '120px', 
                  minWidth: '120px',
                  '&.Mui-selected': {  
                    color: '#5c5c5c',    
                    borderBottom: '4px solid #5c5c5c', 
                    borderRadius: 1,
                  },
                  '&:hover': {
                    bgcolor: '#eeeeee',  
                  },
                }}
              />
              <Tab 
                label="Past" 
                sx={{ 
                  textTransform: 'none', 
                  fontSize: '1rem',
                  pl: 0,
                  pr: 0,
                  justifyContent: 'flex-start',
                  width: '120px', 
                  minWidth: '120px',
                  '&.Mui-selected': {  
                    color: '#5c5c5c',    
                    borderBottom: '4px solid #5c5c5c', 
                    borderRadius: 1,
                  },
                  '&:hover': {
                    bgcolor: '#eeeeee',  
                  },
                }}
              />
            </Tabs>
            {subTab === 0 && (
              <Typography sx={{ mt: 2 }}>Upcoming Sessions</Typography>
            )}
            {subTab === 1 && (
              <Typography sx={{ mt: 2 }}>Past Sessions</Typography>
            )}
          </Box>
        )}
      </Box>
      {/* Snackbar for Success Message */}
      {successMessage && (
        <Snackbar
          open={!!successMessage} // Show when successMessage is not empty
          autoHideDuration={3000}  
          onClose={() => setSuccessMessage('')} 
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} 
        >
          <Alert 
            onClose={() => setSuccessMessage('')} 
            severity="success"
            sx={{ width: '100%' }}
          >
            {successMessage}
          </Alert>
        </Snackbar>  
      )}
      
      
      {/* Snackbar for Error Message */}
      {errorMessage && (
        <Snackbar
          open={!!errorMessage} // Show when errorMessage is not empty
          autoHideDuration={3000} 
          onClose={() => setErrorMessage('')} 
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} 
        >
          <Alert 
            onClose={() => setErrorMessage('')} 
            severity="error" 
            sx={{ width: '100%' }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default Account;
