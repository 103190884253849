import React, { useState } from 'react';
import FormField from './FormField';
import { Button, Box } from '@mui/material';

const ProductForm = ({ productTitle, onFormSubmit }) => {
  const [formData, setFormData] = useState({
    role: '',
    company: ''
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    onFormSubmit(productTitle, formData.role, formData.company);
  };

  // Determine placeholders based on the productTitle
  const rolePlaceholder = productTitle === "Ace with Mocks" ? "Target role (optional)" : "Role (optional)";
  const companyPlaceholder = productTitle === "Ace with Mocks" ? "Target company (optional)" : "Company (optional)";

  return (
    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <FormField
        type="text"
        name="role"
        onChange={handleChange}
        value={formData.role}
        placeholder={rolePlaceholder}
      />
      <FormField
        type="text"
        name="company"
        onChange={handleChange}
        value={formData.company}
        placeholder={companyPlaceholder}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{
          bgcolor: '#1890ff',
          '&:hover': { bgcolor: '#1071e5' },
          borderRadius: '4px',
          height: '48px',
          mt: 1,
          mx: 'auto', 
          width: 'calc(100% - 250px)',
          textTransform: 'none',
          fontSize: '1.0rem',
        }}
      >
        Submit
      </Button>
    </form>
  );
};

export default ProductForm;
