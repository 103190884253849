import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';

const NotFoundPage = () => {
  return (
    <Container sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
      minHeight: '80vh',
      textAlign: 'center'
    }}>
      <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
        Page not found
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Sorry, the page you are looking for either doesn't exist or could not be found.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/"
        sx={{
          bgcolor: '#1890ff', // Set the background color to #1890ff
          '&:hover': { bgcolor: '#1071e5' },
          borderRadius: '4px',
          height: '47px',
          mt: 1,
          mx: 'auto',
          textTransform: 'none',
          fontSize: '1.0rem',
        }}
      >
        Go to Homepage
      </Button>
    </Container>
  );
};

export default NotFoundPage;
