import React from 'react';
import ProductForm from './ProductForm';
import { Paper, Typography } from '@mui/material';

const ProductSection = ({ title, onFormSubmit, sx }) => {
    return (
        <Paper sx={{
            p: 2,
            width: { xs: '100%', sm: 'calc(50% - 100px)' },
            maxWidth: '500px',
            textAlign: 'center',
            my: 2,
            boxShadow: 'none', // Remove box shadow
            border: 'none', // Remove border
            ...sx // Spread additional styles if any
        }}>
            <Typography variant="h4" sx={{ mb: 2, color: '#424242' }}> 
                {title}
            </Typography>
            <ProductForm productTitle={title} onFormSubmit={onFormSubmit} />
        </Paper>
    );
};

export default ProductSection;
