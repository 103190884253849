import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import SessionDetails from '../components/SessionDetails';
import UserInfoForm from '../components/UserInfoForm';
import PaymentForm from '../components/PaymentForm';
import NotFoundPage from './NotFoundPage';
import ErrorMessagePage from './ErrorMessagePage';
import { VALID_PRODUCTS, VALID_PRODUCT_TYPES } from '../constants';
import { getTimezoneAbbreviation } from '../utils/timeUtils';
import { format, parseISO, isValid } from 'date-fns';
import { Alert, Grid, Paper, Box, CircularProgress } from '@mui/material'; 

const BookingReviewAndPaymentPage = () => {

  const { unique_username: paramUsername } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { currentUser } = useAuth();
  
  const state = location.state || {};
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [orderIdentifier, setOrderIdentifier] = useState({ order_id: null, session_id: null, order_uuid: null }); 
  const [apiError, setApiError] = useState(null); 
  const [paymentReady, setPaymentReady] = useState(false); 
  const [error, setError] = useState(false); 
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [fetchingId, setFetchingId] = useState(false);
  
  // Extract values from the state first, fallback to URL if not available
  const [sessionInfo, setSessionInfo] = useState({
    unique_username: state.unique_username || paramUsername,
    productType: state.productType || queryParams.get('productType'),
    product: state.product || queryParams.get('product'),
    targetRole: state.targetRole || queryParams.get('role'),
    targetCompany: state.targetCompany || queryParams.get('company'),
    selectedDate: state.selectedDate || (queryParams.get('selectedDate') ? parseISO(queryParams.get('selectedDate')) : null),
    selectedTimeSlot: state.selectedTimeSlot || (queryParams.get('startTime') && queryParams.get('endTime') ? { start_time: queryParams.get('startTime'), end_time: queryParams.get('endTime') } : null),
    timeZone: state.timeZone || queryParams.get('timeZone'),
    userTimeZone: state.userTimeZone || queryParams.get('userTimeZone'),
  });  

  const [userDetails, setUserDetails] = useState({
    email: '',
    targetRole: sessionInfo.targetRole || '',
    targetCompany: sessionInfo.targetCompany || '',
    sessionFocus: ''
  });

  // Validate the query parameters
  useEffect(() => {
    const orderUuidFromUrl = queryParams.get('order');
    
    // If `order` parameter is valid, skip further validation
    if (orderUuidFromUrl) {
      return;
    }
     
    const { productType, product, selectedDate, selectedTimeSlot, timeZone, userTimeZone } = sessionInfo;

    // 1. Validate `productType`
    if (!productType || !VALID_PRODUCT_TYPES.includes(productType)) {
      setError(true);
      setErrorMessage("A required parameter is missing or invalid. Please check your input and try again."); // Made this generic error message
        // so that this message is applicable in case of invalid 'order' parameter also. Otherwise, the code was becoming unwieldy in the process
        // of achieving custom error message for invalid 'order' param.
      setLoading(false);
      return;
    }

    // 2. Validate `product`
    if (!product || !VALID_PRODUCTS.includes(product)) {
      setError(true);
      setErrorMessage("There is an issue with the 'product' parameter: either it is missing, incorrect, or the provided value is not valid.");
      setLoading(false);
      return;
    }

    // 5. Validate `selectedDate`
    if (!selectedDate || !isValid(selectedDate)) {
      setError(true);
      setErrorMessage("There is an issue with the 'selectedDate' parameter: either it is missing, incorrect, or the provided value is not valid.");
      setLoading(false);
      return;
    }

    // 6. Validate `startTime`
    if (!selectedTimeSlot?.start_time || !/^\d{2}:\d{2}:\d{2}$/.test(selectedTimeSlot.start_time)) {
      setError(true);
      setErrorMessage("There is an issue with the 'startTime' parameter: either it is missing, incorrect, or the provided value is not valid.");
      setLoading(false);
      return;
    }

    // 7. Validate `endTime`
    if (!selectedTimeSlot?.end_time || !/^\d{2}:\d{2}:\d{2}$/.test(selectedTimeSlot.end_time)) {
      setError(true);
      setErrorMessage("There is an issue with the 'endTime' parameter: either it is missing, incorrect, or the provided value is not valid.");
      setLoading(false);
      return;
    }

    // 8. Validate `userTimeZone`
    if (!userTimeZone || !isValidTimeZone(userTimeZone)) {
      setError(true);
      setErrorMessage("There is an issue with the 'userTimeZone' parameter: either it is missing, incorrect, or the provided value is not valid.");
      setLoading(false);
      return;
    }

    // 9. Validate `timeZone`
    const expectedAbbreviation = getTimezoneAbbreviation(userTimeZone || '');
    if (!timeZone || timeZone !== expectedAbbreviation) {
      setError(true);
      setErrorMessage("There is an issue with the 'timeZone' parameter: either it is missing, incorrect, or the provided value is not valid.");
      setLoading(false);
      return;
    }
    
    setLoading(false); // If all validations pass, stop loading
  }, [sessionInfo, queryParams]);

  // Helper function to validate time zones
  const isValidTimeZone = (timezone) => {
    try {
      Intl.DateTimeFormat(undefined, { timeZone: timezone }).format(new Date());
      return true;
    } catch {
      return false;
    }
  };

  const fetchCoachId = async (unique_username) => {
    setFetchingId(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/coaches/${unique_username}/id/`);
      if (!response.ok) {
        throw new Error('Failed to fetch coach ID');
      }
      const data = await response.json();
      return data.id;
    } catch (error) {
      console.error("Error fetching coach ID:", error);
      return null;
    } finally {
      setFetchingId(false);
    }
  };

  const [coachId, setCoachId] = useState(() => {
    const coachIdFromState = state.coachId;
    if (coachIdFromState) {
      return coachIdFromState;
    } else {
      // Fetch coach ID if not available in state
      fetchCoachId(sessionInfo.unique_username).then((id) => {
        if (id) {
          setCoachId(id);
        } else {
          setError(true); // Handle error if ID can't be fetched
        }
      });
      return null;
    }
  }); 

  // Convert the names as expected by the CreateSession API.
  const transformRequestData = (sessionDetails, userInfo) => {

    const formattedDate = format(new Date(sessionDetails.selectedDate), 'yyyy-MM-dd');
    const dayOfWeek = format(new Date(sessionDetails.selectedDate), 'EEEE');
    
    return {
      date: formattedDate,
      weekday: dayOfWeek,
      start_time: sessionDetails.selectedTimeSlot.start_time,
      end_time: sessionDetails.selectedTimeSlot.end_time,
      user_time_zone: sessionDetails.userTimeZone,
      coach_id: coachId,
      product_type: sessionDetails.productType,
      product: sessionDetails.product,
      email: userInfo.email,
      role: userInfo.targetRole,
      company: userInfo.targetCompany,
      session_focus_area: userInfo.sessionFocus
    };
  };

  // CreateSession POST API call
  const handleSubmit = async (userInfo) => {  
    const requestData = transformRequestData(sessionInfo, userInfo);
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/session/create/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        setSubmissionStatus('Submitted');
        setOrderIdentifier({ 
          order_id: responseData.order_id, 
          session_id: responseData.session_id,
          order_uuid: responseData.order_uuid 
        });
        setPaymentReady(true); // Enable the payment form
        window.history.replaceState(null, '', `/coaches/${sessionInfo.unique_username}/booking/review-and-payment?order=${responseData.order_uuid}`);
        return { ok: true, data: responseData };
      } else {
        const errorData = await response.json();
        setSubmissionStatus('Not Submitted');
        setApiError(errorData);
        return { ok: false, error: errorData };
      }
    } catch (error) {
      setSubmissionStatus('Not Submitted');
      setApiError({ error: 'Unexpected error occurred. Please try again later.' });
      return { ok: false, error: 'Unexpected error occurred. Please try again later.' };
    }
  };
  
  // CreateSession PATCH API call
  const handlePatchRequest = async (updatedInfo) => {
    // Transform only the fields that are being updated
    const transformPatchData = (updatedInfo) => {
      const patchData = {};
      if (updatedInfo.email) patchData.email = updatedInfo.email;
      if (updatedInfo.targetRole) patchData.role = updatedInfo.targetRole;
      if (updatedInfo.targetCompany) patchData.company = updatedInfo.targetCompany;
      if (updatedInfo.sessionFocus) patchData.session_focus_area = updatedInfo.sessionFocus;
      return patchData;
    };
  
    // Use transformPatchData to format the fields correctly
    const formattedPatchData = transformPatchData(updatedInfo);
    const patchData = {
      order_id: orderIdentifier.order_id,
      session_id: orderIdentifier.session_id,
      ...formattedPatchData,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/session/create/`, { 
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(patchData),
      });
      if (response.ok) {
        return { ok: true, data: await response.json() };
      } else {
        return { ok: false, error: await response.json() };
      }
    } catch (error) {
      return { ok: false, error: 'An error occurred' };
    }
  };



  // Fetch order details if order_uuid is present in the URL
  const fetchOrderDetails = async (orderUuid) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/order/details/${orderUuid}/`);
      if (response.ok) {
        const data = await response.json();

        mapResponseToState(data);
        setOrderIdentifier((prev) => ({
          ...prev,
          order_id: data.order_id,
          session_id: data.session_id,
          order_uuid: orderUuid,
        }));
        setSubmissionStatus('Submitted');
        setPaymentReady(true);
        setLoading(false);
      } else {
        setApiError('Failed to fetch order details');
        setError(true);
        setLoading(false);
      }
    } catch (error) {
      setApiError('Unexpected error occurred. Please try again later.');
      setError(true);
      setLoading(false);
    }
  };
  
  const mapResponseToState = (data) => {
    const tzAbbreviation = getTimezoneAbbreviation(data.user_time_zone || '');
    setSessionInfo((prev) => ({
      ...prev,
      unique_username: data.coach,
      productType: data.product_offering,
      product: data.product,
      targetRole: data.target_role,
      targetCompany: data.target_company,
      selectedDate: parseISO(data.date),
      selectedTimeSlot: { start_time: data.start_time, end_time: data.end_time },
      timeZone: tzAbbreviation,
      userTimeZone: data.user_time_zone,
      clientSecret: data.client_secret,
      productOffering: data.product_offering,
      amount: data.amount
    }));
    
    setUserDetails({
      email: data.user_email,
      targetRole: data.target_role,
      targetCompany: data.target_company,
      sessionFocus: data.session_focus_area, // Fallback to sessionInfo default if not present
    });
  };

  useEffect(() => {
    const orderUuidFromUrl = queryParams.get('order');
    if (orderUuidFromUrl) {
      fetchOrderDetails(orderUuidFromUrl);
    } else {
      setLoading(false); // Stop loading if no order UUID is present
    }
  }, []);
  
  // Here, handle the submission of the user info
  const handleBackClick = () => {
    const queryParams = new URLSearchParams({
      productType: sessionInfo.productType,
      product: sessionInfo.product,
      role: sessionInfo.targetRole || '',
      company: sessionInfo.targetCompany || '',
    }).toString();

    navigate(`/coaches/${sessionInfo.unique_username}/booking?${queryParams}`, {
      state: {
        selectedDate: sessionInfo.selectedDate,
        selectedTimeSlot: sessionInfo.selectedTimeSlot,
        timeZone: sessionInfo.timeZone,
        userTimeZone: sessionInfo.userTimeZone,
      },
    });
  };
  
  if (error) {
    if (errorMessage) {
      return <ErrorMessagePage message={errorMessage} />; // Use the new ErrorMessagePage component
    } else {
      return <NotFoundPage />; // Fallback to NotFoundPage if no specific error message is set
    }
  }

  if (loading || fetchingId) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </div>;
  }

  const initialSessionFocus = sessionInfo.product === "Ace with Mocks" ? "letCoachDecide" : sessionInfo.product === "Burnout to Break Free" ? "letCoachDecide" : '';
  
  return (
    <Box sx={{ flexGrow: 1, p: 0 }}>
      {/* Persistent Info Banner */}
      {submissionStatus === 'Submitted' && (
        <Alert 
          severity="info" 
          sx={{ 
            mb: 2, 
            p: 0.5,  
            color: '#0277bd',  
             
          }}
        >
          You have successfully initiated an order. Navigating back may cause you to lose progress and require re-entering details, such as your email.
        </Alert>
      )}
      <Grid container spacing={0} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Grid item xs={12} md={8} sx={{ width: { md: '60%' } }}>
          <Paper elevation={0} sx={{ p: 0, m: 1 }}>
            {sessionInfo && <SessionDetails sessionInfo={sessionInfo} />}
            <UserInfoForm
              initialTargetRole={userDetails.targetRole || ''}
              initialTargetCompany={userDetails.targetCompany || ''}
              initialEmail={userDetails.email || ''}
              initialSessionFocus={userDetails.sessionFocus || initialSessionFocus}
              product={sessionInfo.product}
              onSubmitUserInfo={handleSubmit}
              submissionStatus={submissionStatus}
              onPatchUserInfo={handlePatchRequest} // Pass the patch handler as a prop
              onBackClick={handleBackClick} 
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} sx={{ width: { md: '40%' } }}>
          <PaymentForm 
            isPaymentReady={paymentReady} 
            orderIdentifier={orderIdentifier} 
            uniqueUsername={sessionInfo.unique_username}
            clientSecret={sessionInfo.clientSecret}
            productOffering={sessionInfo.productOffering}
            amount={sessionInfo.amount}
            // updateURLWithOrderUUID={updateURLWithOrderUUID} // Pass the callback to update the URL 
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default BookingReviewAndPaymentPage;