// ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Firebase auth context

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();

  // Redirect to Home if not authenticated
  if (!currentUser) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
