import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Button, Container, TextField, Typography, Link, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FormField from '../components/FormField';
import getErrorMessage from '../utils/getErrorMessage';
import { useGoogleAuth } from '../hooks/useGoogleAuth';
import { auth } from '../firebase';

const SignIn = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // New state for sign-in loading spinner
  const navigate = useNavigate();
  const location = useLocation();
  const { handleGoogleAuth } = useGoogleAuth();
  const theme = useTheme();  // Use the theme for consistent styling

  // Fetch the CSRF token from your backend
  const getCsrfToken = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get_csrf_token/`, {
        method: 'GET',
        credentials: 'include', // Include cookies for CSRF token
      });
      if (!response.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
      const data = await response.json();
      return data.csrfToken;
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
      throw error;
    }
  };

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit: async (values) => {
      setLoading(true); // Set loading to true when form is submitted
      setError(''); // Clear any existing errors
      try {
        // Sign in with Firebase Authentication
        const userCredential = await auth.signInWithEmailAndPassword(values.email, values.password);
        const token = await userCredential.user.getIdToken(); // Get Firebase ID token
        const csrfToken = await getCsrfToken();
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/signin/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,  // Pass the Firebase token to the backend
            'X-CSRFToken': csrfToken,
          },
          credentials: 'include', // Include cookies for session handling
        });

        if (!response.ok) {
          throw new Error('Failed to sign in with backend');
        }

        // Determine where to redirect after sign-in
        const redirectTo = location.state?.from?.pathname || '/'; // Use the stored location or default to home
        const redirectSearch = location.state?.from?.search || ''; // Preserve any query params

        navigate(`${redirectTo}${redirectSearch}`); // Redirect to the previous page
      } catch (firebaseError) {
        setLoading(false); // Stop loading on error
        setError(getErrorMessage(firebaseError));
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs" sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start',
      minHeight: '60vh',  // Adjust minimal height for better top alignment
      pt: { xs: 2, sm: 3, md: 8 },  // Responsive padding top
      '& .MuiTextField-root': { m: 1, width: '100%', borderRadius: '4px' },
      '& .MuiButton-root': { m: 1, width: '100%', borderRadius: '4px', textTransform: 'none' },
      '@media (max-width:600px)': {
        '& .MuiButton-root': { height: '48px' }, // Adjust buttons height in smaller devices
        '& .MuiTextField-root': { height: '48px' }, // Adjust text fields height in smaller devices
      }
    }}>
      <Typography component="h1" variant="h5" sx={{
        fontWeight: 'bold',
        mb: 1,
        fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' }, // Responsive font size
      }}>
        Sign In
      </Typography>
      <Button
        variant="contained"
        onClick={handleGoogleAuth}
        sx={{
          mt: 3, mb: 2,
          bgcolor: '#1890ff',
          '&:hover': { bgcolor: '#1071e5' },
          height: { xs: '48px', sm: '52px' },  // Responsive button height
          fontSize: '1.0rem'
        }}
      >
        Sign In with Google
      </Button>
      <Typography variant="caption" sx={{
        my: 1,
        fontSize: '1.0rem',
        display: 'block'
      }}>
        OR
      </Typography>
      <Box component="form" onSubmit={formik.handleSubmit} sx={{ width: '100%', mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={formik.handleChange}
          value={formik.values.email}
          variant="outlined"
          sx={{
            borderRadius: '4px',
            height: { xs: '48px', sm: '52px' },  // Responsive field height
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={formik.handleChange}
          value={formik.values.password}
          variant="outlined"
          sx={{
            borderRadius: '4px',
            height: { xs: '48px', sm: '52px' },  // Responsive field height
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 3, mb: 2,
            bgcolor: '#1890ff',
            '&:hover': { bgcolor: '#1071e5' },
            height: { xs: '48px', sm: '52px' },  // Responsive button height
            fontSize: '1.0rem'
          }}
          disabled={loading} // Disable button when loading
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
        </Button>
        {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
      </Box>
      <Link 
        component={RouterLink} 
        to="/forgot-password" 
        state={{ from: location.state?.from }} // Pass the state from SignIn to Register
        variant="body2" 
        sx={{
          mt: 2,
          fontSize: { xs: '0.9rem', sm: '1.0rem' },  // Responsive font size for links
          textDecoration: 'underline'
      }}>
        Forgot Password?
      </Link>
      <Typography variant="body2" sx={{
        mt: 1,
        fontSize: { xs: '0.9rem', sm: '1.0rem' },  // Responsive font size for text
      }}>
        Not a member yet?{' '}
        <Link 
          component={RouterLink} 
          to="/register" 
          state={{ from: location.state?.from }} // Pass the state from SignIn to Register
          variant="body2" 
          sx={{
            textDecoration: 'underline',
            fontSize: { xs: '0.9rem', sm: '1.0rem' }  // Responsive font size for links
        }}>
          Register for free.
        </Link>
      </Typography>
    </Container>
  );
};

export default SignIn;
