// src/utils/timeUtils.js
export const convertTimeTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(':');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const hours12 = ((hours % 12) || 12).toString().padStart(2, '0'); // Convert "00" to "12"
    return `${hours12}:${minutes} ${ampm}`;
  };
  
export const getTimezoneAbbreviation = (timezone) => {
  // Check if the timezone has a manual mapping
  if (timezoneAbbreviations[timezone]) {
    return timezoneAbbreviations[timezone];
  }
  try {
    const date = new Date();
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      timeZoneName: 'short',
    });
    const parts = formatter.formatToParts(date);
    const tzPart = parts.find(part => part.type === 'timeZoneName');
    // If Intl.DateTimeFormat returns a GMT offset format or tzPart is undefined, fall back to the original API timezone string
    if (!tzPart || tzPart.value.startsWith('GMT')) {
      return timezone; // Fallback to the original timezone string
    }

    return tzPart.value; // Return the abbreviation
  } catch (error) {
    console.error(`Invalid time zone specified: ${timezone}`);
    return null; // Return null or a specific fallback value when an error occurs
  }
};

export const timezoneAbbreviations = {
  'Asia/Kolkata': 'IST',
  'Asia/Calcutta': 'IST',
  // Add more mappings as needed
};