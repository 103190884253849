import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { Grid, Paper, Typography, Button, Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

// Replace your Stripe public key below
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutForm = ({ clientSecret, uniqueUsername, productDetails, orderIdentifier }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [paymentElement, setPaymentElement] = useState(null);
  // const [addressElement, setAddressElement] = useState(null);

  useEffect(() => {

    if (!stripe || !elements || !clientSecret) {
      return;
    }

    if (!paymentElement) {
      const appearance = { 
        theme: 'stripe',
        variables: { colorPrimaryText: '#262626' }
      };
      const paymentElementOptions = {
        clientSecret,
        appearance,
        layout: "tabs"  // Setting the layout to tabs
      };
      const newPaymentElement = elements.create('payment', paymentElementOptions);
      newPaymentElement.mount('#payment-element');
      setPaymentElement(newPaymentElement); 
    }

    // if (!addressElement) {
    //   const addressOptions = {
    //     clientSecret,
    //     mode: 'billing'
    //   };
    //   const newAddressElement = elements.create('address', addressOptions);
    //   newAddressElement.mount('#address-element');
    //   setAddressElement(newAddressElement);
    // }

    return () => {
      if (paymentElement) {
        paymentElement.unmount();  // Unmount the payment element when the component unmounts
      }
      // if (addressElement) {
      //   addressElement.unmount();  // Ensure to unmount the address element when the component unmounts
      // }
    };
  }, [stripe, elements, clientSecret, paymentElement]); //, addressElement]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    
    if (!stripe || !elements) {
      setMessage('Stripe.js has not loaded yet.');
      setIsLoading(false);
      return;
    }
    
    const paymentElement = elements.getElement('payment');
    
    if (!paymentElement) {
      setMessage('Payment element not found.');
      setIsLoading(false);
      return;
    }
    
    const returnUrl= `${process.env.REACT_APP_RETURN_URL}/coaches/${uniqueUsername}/booking/confirmation?order=${orderIdentifier.order_uuid}`;

    // Replace the current history entry with the new URL
    window.history.replaceState(null, '', returnUrl);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    if (error) {
      setMessage(`Payment failed: ${error.message}`);
    } else {
      setMessage('Payment processing...');
    }

    setIsLoading(false);
  };
  
  return (
    <>
      <Typography variant="h6" sx={{ mb: 2, mt: 1 }}>Payment</Typography>
      <Paper elevation={1} sx={{ p: 3, mt: 1, mb: 20 }}>
        <form onSubmit={handleSubmit}>
          <Table sx={{ mb: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '0.75px solid #e2e2e2' }}>ITEM</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '0.75px solid #e2e2e2' }}>PRICE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ p: 1, textAlign: 'left', fontSize: '1rem' }}>{productDetails.product_offering}</TableCell>
                <TableCell sx={{ p: 1, textAlign: 'left',  fontSize: '1.2rem', fontWeight: 'bold', color: '#1890ff' }}>{productDetails.amount}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div id="payment-element" />
          {/* <div id="address-element" /> */}
          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            disabled={isLoading || !stripe}
            sx={{ 
              mt: 2, 
              width: '100%',
              bgcolor: '#1890ff',
              '&:hover': { bgcolor: '#1071e5' },
              textTransform: 'none',
              fontSize: '1rem',
              alignSelf: 'center',
              borderRadius: '4px', 
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : "Pay Now"}
          </Button>
          {message && <Typography sx={{ color: 'rgb(105, 115, 134)', fontSize: 16, lineHeight: '20px', pt: 2, textAlign: 'center' }}>{message}</Typography>}
        </form>
      </Paper>
    </>
  );
};

const PaymentForm = ({ isPaymentReady, orderIdentifier, uniqueUsername, clientSecret, productOffering, amount }) => {
  const navigate = useNavigate();
  const [productDetails, setProductDetails] = useState({ product_offering: productOffering || '', amount: amount || '' });
  const [clientSecretState, setClientSecretState] = useState(clientSecret || '');
  const [error, setError] = useState(null); 

  useEffect(() => {

    if (!isPaymentReady || !orderIdentifier.order_id || clientSecretState) 
      return;

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/payments/create-payment-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ order_id: orderIdentifier.order_id }),
    })
    .then(response => response.json())

    .then(data => {
      if (data.clientSecret) {
        setClientSecretState(data.clientSecret);
        setProductDetails({ 
          product_offering: data.product_offering, 
          amount: data.amount 
        });
        
      } else {
        setError('Failed to initialize payment. Please try again or contact support@eastbadge.com');
      }
    })

    .catch(error => {
      console.error('Error fetching clientSecret:', error);
      setError('Failed to initialize payment. Please try again or contact support@eastbadge.com');
    });
  }, [isPaymentReady, orderIdentifier.order_id, clientSecretState, uniqueUsername, navigate ]);

  if (error) {
    return (
      <div>
        <p style={{ color: 'red' }}>{error}</p>
      </div>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      {isPaymentReady && clientSecretState ? (
        <Elements stripe={stripePromise} options={{ clientSecret: clientSecretState }}>
          <CheckoutForm 
            orderIdentifier={orderIdentifier}
            uniqueUsername={uniqueUsername}
            clientSecret={clientSecretState} 
            productDetails={productDetails}
          />
        </Elements>
      ) : (
        <Typography variant="body1"></Typography>
      )}
    </Box>
  );
};

export default PaymentForm;
