import React from 'react';
import { Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const RatingStars = ({ rating }) => {
    // Ensure the rating is a number and clamp the value between 0 and 5
    rating = !isNaN(rating) ? Math.min(Math.max(0, rating), 5) : 0;

    const fullStars = Math.floor(rating);
    const halfStar = (rating % 1) >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;

    return (
        <Box display="flex">
            {Array(fullStars).fill(0).map((_, i) => <StarIcon key={`full-${i}`} sx={{ color: '#ffc107' }} />)}
            {halfStar === 1 && <StarHalfIcon key="half" sx={{ color: '#ffc107' }} />}
            {Array(emptyStars).fill(0).map((_, i) => <StarBorderIcon key={`empty-${i}`} sx={{ color: '#ffc107' }} />)}
        </Box>
    );
};

export default RatingStars;
