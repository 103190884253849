import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

import BookSessionCard from '../components/BookSessionCard';
import FAQSection from '../components/FAQSection';
import RatingStars from '../components/RatingStars';
import { formatDate } from '../utils/dateUtils';
import NotFoundPage from './NotFoundPage';
import ErrorMessagePage from './ErrorMessagePage';
import { VALID_PRODUCTS } from '../constants'; 

import { Button, Typography, Grid, Card, Box, Container, useTheme, useMediaQuery, CircularProgress } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';  // Star icon for rating
import CheckCircleIcon from '@mui/icons-material/CheckCircle';  // Badge icon for sessions
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosNewIcon from '@mui/icons-material/ArrowForwardIos'; 

const CoachProfilePage = () => {
  const { unique_username } = useParams();
  const location = useLocation();

  const state = location.state || {};
  const queryParams = new URLSearchParams(location.search);
  const product = state.product || queryParams.get('product') || '';
  const role = state.role || queryParams.get('role') || '';
  const company = state.company || queryParams.get('company') || '';
  const { id: stateId } = state;
  const [id, setId] = useState(stateId);
  
  const [fetchingId, setFetchingId] = useState(!stateId); // Initial state is true if stateId is not available
  const [coachProfile, setCoachProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [error, setError] = useState(false); // To redirect to Not Found Page
  const [errorMessage, setErrorMessage] = useState('');

  // Ref to track the scrolling container
  const scrollRef = useRef(null);
  // State to manage arrow visibility
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  useEffect(() => {
    // Validate product
    if (!product || !VALID_PRODUCTS.includes(product)) {
      setError(true);
      setErrorMessage("There is an issue with the 'product' parameter: either it is missing, incorrect, or the provided value is not valid.");
      setLoading(false);
      return;
    }
  }, [product]);

  const fetchCoachId = async () => {
    setFetchingId(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/coaches/${unique_username}/id/`);
      if (!response.ok) {
        setError(true);
        return;
      }
      const data = await response.json();
      setId(data.id);
    } catch (error) {
      console.error("Error fetching coach ID:", error);
      setError(true);
    } finally {
      setFetchingId(false); // Set fetching ID to false
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        await fetchCoachId();
      } else {
        setFetchingId(false); // If id is already available, set fetching ID to false
      }
    };

    fetchData();
  }, [unique_username, id]);

  useEffect(() => {
    if (!fetchingId) {
      if (!id || !product) {
        setLoading(false);
        setError(true);
        return;
      }

      const fetchCoachProfile = async () => {
        setLoading(true);
        try {
          // const response = await fetch(`http://127.0.0.1:8000/api/v1/coaches/${id}/profile?product=${product}`);
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/coaches/${id}/profile?product=${product}`);
          if (!response.ok) {
            throw new Error('Failed to fetch coach profile');
          }
          const data = await response.json();
          setCoachProfile(data);
        } catch (error) {
          console.error("Error fetching coach profile:", error);
          setError(true); // Set error state to true if there's an error during fetching
        } finally {
          setLoading(false);
        }
      };
      fetchCoachProfile();
    }
  }, [id, product, fetchingId ]);

  // Function to check scroll position and update state
  const checkForScrollPosition = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft !== scrollWidth - clientWidth);
    }
  };

  // Function to scroll left
  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -150, behavior: 'smooth' });
    checkForScrollPosition();
  };

  // Function to scroll right
  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 150, behavior: 'smooth' });
    checkForScrollPosition();
  };

  useEffect(() => {
    const handleResize = () => checkForScrollPosition();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    checkForScrollPosition();
  }, [coachProfile]);
  
  if (loading || fetchingId) {  // Show CircularProgress while loading or fetching ID
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </div>;
  }
  
  if (error) {
    if (errorMessage) {
      return <ErrorMessagePage message={errorMessage} />; // Use the new ErrorMessagePage component
    } else {
      return <NotFoundPage />; // Fallback to NotFoundPage if no specific error message is set
    }
  }

  if (!coachProfile) {
    return <div>Coach profile not found</div>; // Or any other loading state
  }

  return (
    <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: 2 }}>
            <Button 
              onClick={() => navigate(`/coaches?product=${product}&role=${role}&company=${company}`)} 
              sx={{ 
                color: '#4C516D', 
                textDecoration: 'none', 
                background: 'transparent', 
                border: 'none', 
                cursor: 'pointer', 
                fontSize: isSmallScreen ? '0.875rem' : '1rem', 
                textTransform: 'none', 
                borderRadius: 1, 
                ':hover': { backgroundColor: '#fafafa', width: 'fit-content' } 
              }}
            >
              &lt; back to coaches
            </Button>
          </Box>
          <Typography variant={isSmallScreen ? 'h6' : 'h5'} sx={{ mb: 2, fontWeight: 'bold', textAlign: 'left' }}>
            {coachProfile?.present_role} at {coachProfile?.present_company}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
              <CheckCircleIcon sx={{ color: '#64B5F6', backgroundColor: '#E3F2FD', borderRadius: '50%', mr: 1 }} />
              <Typography variant="h6" sx={{ fontSize: '1.5em', fontWeight: 'bold' }}>
                {coachProfile?.sessions_completed}
              </Typography>
              <Typography sx={{ fontSize: '1em', ml: 1 }}>Sessions done</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
              <StarIcon sx={{ color: '#FFD700', backgroundColor: '#FFF9C4', borderRadius: '50%', mr: 1 }} />
              {coachProfile?.rating ? (
                <>
                  <Typography variant="h6" sx={{ fontSize: '1.5em', fontWeight: 'bold' }}>
                    {coachProfile.rating}
                  </Typography>
                  <Typography sx={{ fontSize: '1em', ml: 0 }}>/5</Typography>
                  <Typography sx={{ fontSize: '1em', ml: 1 }}>Overall rating</Typography>
                </>
              ) : (
                <Typography sx={{ fontSize: '1em', ml: 1 }}>Not rated yet</Typography>
              )}
          </Box>
          </Box>

          {coachProfile.bio && (
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6">About</Typography>
              <Typography>{coachProfile.bio}</Typography>
            </Box>
          )}
          {/* Experience section from here */}
          {coachProfile.past_experience && coachProfile.past_experience.length > 0 && (
            <Box sx={{ mb: 2, position: 'relative', overflow: 'hidden' }}>
              <Typography variant="h6">Experiences</Typography>
              <Box
                ref={scrollRef}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  overflowX: 'auto',
                  scrollBehavior: 'smooth',
                  p: 1,
                  position: 'relative',
                  '&::-webkit-scrollbar': { display: 'none' },
                  scrollbarWidth: 'none',
                }}
                onScroll={checkForScrollPosition}
              >
                {coachProfile.past_experience.map((exp, index) => (
                  <Card
                    key={index}
                    sx={{
                      border: 0,
                      borderColor: 'divider',
                      p: 1,
                      m: 1,
                      minWidth: 150, // Minimum width for readability
                      maxWidth: {   
                        xs: '50%',  // max-width 90% for extra-small screens (mobile)
                        sm: '25%',  // max-width 75% for small screens (small tablets)
                        md: '25%',  // max-width 50% for medium screens (large tablets)
                        lg: '25%',  // max-width 25% for large screens (desktops)
                        xl: 200     // max-width 200px for extra-large screens (large desktops)
                      },
                      height: 200,
                      textAlign: 'center',
                      borderRadius: '10px',
                      borderWidth: '0px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1, lineHeight: 1.2 }}>{exp.role}</Typography>
                    <Typography sx={{ mb: 1, lineHeight: 1.2 }}>{exp.company}</Typography>
                    <Typography sx={{ mb: 1, lineHeight: 1.2 }}>{new Date(exp.start_date).getFullYear()} - {exp.end_date ? new Date(exp.end_date).getFullYear() : 'Present'}</Typography>
                    {exp.level && <Typography sx={{ lineHeight: 1.2 }}>Level {exp.level}</Typography>}
                  </Card>
                ))}
              </Box>
              {canScrollLeft && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: 10,  // Adjust this value to move the left arrow further left
                    transform: 'translate(-50%, -50%)',  // Adjust horizontal shift
                    zIndex: 10,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    borderRadius: '0%',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgba(245, 245, 245, 0.9)',
                    }
                  }}
                  onClick={scrollLeft}
                >
                  <ArrowBackIosNewIcon sx={{ cursor: 'pointer', color: '#666' }} />
                </Box>
              )}
              {canScrollRight && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    right: 5,  // Adjust this value to move the right arrow further right
                    transform: 'translate(50%, -50%)',  // Adjust horizontal shift
                    zIndex: 10,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    borderRadius: '0%',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgba(245, 245, 245, 0.9)',
                    }
                  }}
                  onClick={scrollRight}
                >
                  <ArrowForwardIosNewIcon sx={{ cursor: 'pointer', color: '#666' }} />
                </Box>
              )}
            </Box>
          )}
          {/* Experience section till here */}
          {/* Reviews section stars here */}
          <Typography variant="h6" sx={{ mb: 1 }}>Reviews ({coachProfile.user_feedback.filter((feedback) => feedback.session_feedback).length})</Typography>
          {coachProfile.user_feedback
            .filter((feedback) => feedback.session_feedback) // Filter out empty or null feedback
            .map((review, index) => (
              <Box key={index} sx={{ 
                  mb: 1, 
                  p: 2, 
                  borderRadius: 2, 
                  border: '1px solid #f5f5f5',  // Thin border around the entire review
                  backgroundColor: 'none' 
                }}>
                
                {/* Unified background color box */}
                <Box sx={{ 
                    borderRadius: 'inherit', // Inherit borderRadius from the parent
                    backgroundColor: '#f5f5f5', // Consistent background color for company-role, stars, and date
                    p: 1, // Padding inside the box to space contents from the border
                    mb: 1 // Space between this box and the main review content below
                  }}>
                  
                  {/* Company and Role */}
                  <Typography sx={{ fontSize: '0.95em', mb: 1 }}>{review.target_company} - {review.target_role}</Typography>
                  
                  {/* Rating Stars and Date in the same row */}
                  <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center'
                    }}>
                    <RatingStars rating={Number(review.session_rating) || 0} />
                    <Typography sx={{ fontSize: '0.95em', whiteSpace: 'nowrap', ml: 1 }}>{formatDate(review.feedback_date)}</Typography>
                  </Box>
                </Box>
                
                {/* Review feedback text below the box */}
                <Typography variant="subtitle1">{review.session_feedback}</Typography>
              </Box>
            ))}
            {/* Reviews section end here */}
        
          <FAQSection product={product} validProducts={VALID_PRODUCTS} />
        </Grid>

        <Grid item xs={12} md={4.2} >
          <Box sx={{ width: '100%', position: 'sticky', top: 10, p: 2.5, height: '100vh' }}>
            <BookSessionCard product={product} uniqueUsername={unique_username} coachId={id} role={role} company={company} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CoachProfilePage;
