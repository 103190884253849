import React, { useState, useEffect } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Box, useTheme, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = ({ question, answer }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Accordion sx={{ 
      boxShadow: 'none', 
      background: 'transparent', 
      mb: 1, 
      "&:before": { display: 'none' },
      // Adjust the border radius and padding for small screens
      ...(isSmallScreen ? { borderRadius: '2px' } : { borderRadius: '4px' })
    }}>
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />} 
        sx={{ 
          backgroundColor: '#fafafa', 
          borderRadius: '4px', 
          ':hover': { backgroundColor: '#f0f0f0' },
        }}
      >
        <Typography sx={{ fontWeight: 'bold', cursor: 'pointer' }}>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: 'transparent', pt: 2, pb: 2.5 }}>
        <Typography>{answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const FAQSection = ({ product, validProducts }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const faqsForProducts = validProducts.reduce((acc, prod) => {
    if (prod === 'Ace with Mocks') {
      acc[prod] = [
        {
          question: "Can I use the mock interview time for general discussion?",
          answer: "Yes, you can."
        },
        {
          question: "Can I get a refund?",
          answer: "Each session includes personalized guidance and Q&A tailored to your needs."
        },
        {
          question: "How long is each session?",
          answer: "Each session is typically one hour long, but you can request longer sessions."
        },
        {
          question: "Can I cancel or reschedule a session?",
          answer: "Yes, you can cancel or reschedule by contacting the coach 24 hours in advance."
        },
        {
          question: "What if I'm not satisfied with my coaching session?",
          answer: "We strive for satisfaction. If you're not happy with the session, please contact customer support."
        },
      ];
    } else if (prod === 'Burnout to Break Free') {
      acc[prod] = [
        {
          question: "Can I use the burnout session time for general discussion?",
          answer: "Yes, you can."
        },
        {
          question: "Can I get a refund?",
          answer: "Each session includes personalized guidance and Q&A tailored to your needs."
        },
        {
          question: "How long is each session?",
          answer: "Each session is typically one hour long, but you can request longer sessions."
        },
        {
          question: "Can I cancel or reschedule a session?",
          answer: "Yes, you can cancel or reschedule by contacting the coach 24 hours in advance."
        },
        {
          question: "What if I'm not satisfied with my coaching session?",
          answer: "We strive for satisfaction. If you're not happy with the session, please contact customer support."
        },
      ];
    }
    return acc;
    // ... other products
  }, {});

  // Default FAQs if product not found
  const defaultFaqs = [
    {
        question: "How can I book a session?",
        answer: "You can book a session by selecting a coach and clicking the 'Book an hour' button."
    },
    // ... more default FAQs
  ];

  const faqs = product ? faqsForProducts[product] || defaultFaqs : defaultFaqs;

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>FAQs</Typography>
      {faqs.map((faq, index) => (
        <FAQ key={index} question={faq.question} answer={faq.answer} />
      ))}
    </Box>
  );
};

export default FAQSection;
