import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Box, Button, TextField, Typography, Link } from '@mui/material';

import getErrorMessage from '../utils/getErrorMessage';
import { auth } from '../firebase';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    const oobCode = new URLSearchParams(location.search).get('oobCode');
    try {
      await auth.confirmPasswordReset(oobCode, newPassword);
      setSuccess(true);
      navigate('/signin');
    } catch (firebaseError) {
      setError(getErrorMessage(firebaseError));
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
      minHeight: '60vh',  // Ensures the form is vertically centered in all viewports
      pt: 2,
      '& .MuiTextField-root': { m: 1, width: '100%', borderRadius: '4px' },
      '& .MuiButton-root': { m: 1, width: '100%', borderRadius: '4px', textTransform: 'none' },
      '@media (max-width:600px)': {
        '& .MuiButton-root': { height: '48px' },
        '& .MuiTextField-root': { height: '48px' },
      },
      '@media (min-width:600px)': {
        '& .MuiButton-root': { height: '52px' },
        '& .MuiTextField-root': { height: '52px' },
      }
    }}>
      <Typography component="h1" variant="h5" sx={{
        fontWeight: 'bold',
        mb: 3,
        fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' },
      }}>
        Reset Password
      </Typography>
      {success ? (
        <Typography sx={{ mt: 2, textAlign: 'center' }}>
          Password reset successful. <Link component="button" onClick={() => navigate('/signin')} sx={{ textDecoration: 'underline', cursor: 'pointer' }}>Sign In</Link>
        </Typography>
      ) : (
        <Box component="form" onSubmit={handleResetPassword} sx={{ width: '100%' }}>
          <TextField
            type="password"
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            variant="outlined"
            sx={{ borderRadius: '4px' }}
          />
          <TextField
            type="password"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            variant="outlined"
            sx={{ borderRadius: '4px' }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 2, mb: 2,
              bgcolor: '#1890ff',
              '&:hover': { bgcolor: '#1071e5' },
              height: { xs: '48px', sm: '52px' },  // Responsive heights
              fontSize: '1.0rem'
            }}
          >
            Reset Password
          </Button>
          {error && <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>{error}</Typography>}
        </Box>
      )}
      <Button onClick={() => navigate('/signin')} sx={{
        mt: 2,
        bgcolor: 'transparent',
        color: '#1890ff',
        '&:hover': { bgcolor: 'transparent', textDecoration: 'underline' },
        fontSize: '1.0rem'
      }}>
        Sign In Page
      </Button>
    </Container>
  );
};

export default ResetPassword;
