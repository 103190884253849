import React from 'react';
import { Typography, Paper, Box } from '@mui/material'; // Import Material-UI components
import { format } from 'date-fns';
import { formatDate } from '../utils/dateUtils';
import { convertTimeTo12HourFormat } from '../utils/timeUtils';

const SessionDetails = ({ sessionInfo }) => {
  if (!sessionInfo || !sessionInfo.selectedDate || !sessionInfo.selectedTimeSlot) {
    return <div>Session details are not available.</div>;
  }
  const formattedDate = format(sessionInfo.selectedDate, 'do MMM'); // e.g., "20 March"
  const dayOfWeek = format(sessionInfo.selectedDate, 'EEEE'); // e.g., "Wednesday"
  const formattedStartTime = sessionInfo.selectedTimeSlot ? convertTimeTo12HourFormat(sessionInfo.selectedTimeSlot.start_time) : '';
  const formattedEndTime = sessionInfo.selectedTimeSlot ? convertTimeTo12HourFormat(sessionInfo.selectedTimeSlot.end_time) : '';
  const timeZone = sessionInfo.timeZone || ''; // e.g., "Asia/Calcutta IST"

  return (
    <>
      <Typography variant="h6" sx={{ mb: 2, mt: 1 }}>Session details</Typography>
      <Paper 
        sx={(theme) => ({ 
          color: '#000',
          padding: '0px', // Remove padding to ensure grey background extends fully
          margin: '0px 0px 0px 0px',
          borderRadius: '4px',
          position: 'relative',
          fontFamily: 'Arial, sans-serif',
          '& h6': { marginBottom: '15px' },
          [theme.breakpoints.down('sm')]: {
            margin: '0px 0px', // Adjust for smaller screens
          },
        })} 
        elevation={1}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '20px',
          backgroundColor: '#f5f5f5',
          borderRadius: '4px 4px 0 0', // Only round bottom edges
          margin: '0px', // Remove margin
        }}>
          <Box sx={{ paddingLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}> {/* Add flex properties */}
            <Typography sx={{ fontSize: '2rem', color: '#616161', alignSelf: 'flex-start', pt: 2.4 }}>{formattedDate}</Typography>
            <Typography sx={{ fontSize: '1rem', color: '#616161', alignSelf: 'flex-start', mt: 0, ml: 2.5 }}>{dayOfWeek}</Typography> {/* Adjust alignment and margin */}
          </Box>
          <Box>
            <Typography sx={{ fontSize: '2rem', color: '#616161' }}>{`${formattedStartTime} - ${formattedEndTime}`}</Typography>
          </Box>
          <Box sx={{ paddingRight: '10px' }}> {/* Add padding to the right */}
            <Typography sx={{ fontSize: '2rem', color: '#616161' }}>{timeZone}</Typography>
          </Box>
        </Box>
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 4fr',
          gap: '0.5rem',
          alignItems: 'center',
          margin: '10px 20px',
          p: 3,
        }}>
          <Typography sx={{ textAlign: 'right', paddingRight: '10px', color: '#747474' }}>Coach  :</Typography>
          <Typography sx={{ textAlign: 'left', fontWeight: 'normal' }}>{sessionInfo.unique_username}</Typography>
          <Typography sx={{ textAlign: 'right', paddingRight: '10px', color: '#747474' }}>Session  :</Typography>
          <Typography sx={{ textAlign: 'left', fontWeight: 'normal' }}>{sessionInfo.product}</Typography>
          <Typography sx={{ textAlign: 'right', paddingRight: '10px', color: '#747474' }}>Session type  :</Typography>
          <Typography sx={{ textAlign: 'left', fontWeight: 'normal' }}>{sessionInfo.productType}</Typography>
        </Box>
      </Paper>
    </>
  );
};

export default SessionDetails;
