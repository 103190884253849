const getErrorMessage = (firebaseError) => {
    
    const errorCode = firebaseError.code;
    const errorMessage = firebaseError.message;
  
    // Check for specific phrases in Firebase error messages
    if (errorMessage.includes("too many attempts")) {
      console.log("1: ", firebaseError);
      return 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.';
    }
    
    if (errorMessage.includes("The email address is badly formatted.")) {
      console.log("2: ", firebaseError);
      return 'Invalid login credentials.';
    }
    
    if (errorMessage.includes("A non-empty password must be provided (auth/missing-password)")) {
        console.log("3: ", firebaseError);
        return 'Password is missing.';
      }
    
    if (errorMessage.includes("INVALID_EMAIL")) {
        console.log("4: ", firebaseError);
        return 'Invalid email.';
    }
    
    if (errorMessage.includes("The email address is already in use by another account.")) {
        console.log("5: ", firebaseError);
        return 'Email already exists, sign-in instead.';
    }
    console.log("6: ", firebaseError);
    
    
    // ... add more if statements as needed ...
  
    // Mapping Firebase error codes to custom messages
    const errorCodeToMessage = {
      'auth/email-already-in-use': 'Email already exists, sign in instead.',
      'auth/user-disabled': 'Invalid login credentials.',
      'auth/invalid-email': 'Invalid login credentials.',
      'auth/missing-email': 'Invalid login credentials.',
      'auth/missing-password': 'Invalid login credentials.',
      'auth/invalid-credential': 'Invalid login credentials.',
      'auth/user-not-found': 'User not found',
      'auth/wrong-password': 'Incorrect password',
      'auth/weak-password': 'Weak password. Use a password with at least 6 characters.',
      'auth/operation-not-allowed': 'Registration is currently not allowed. Please try again later.',
        
      // ... other mappings ...
    };
    console.log("7: ", firebaseError);
    if (errorCodeToMessage[errorCode]) {
        return errorCodeToMessage[errorCode];
      }
    // Fallback to a default message for unmapped codes or phrases
    console.log("8: ", firebaseError);
    return 'An unexpected error occurred. Try again.';
  };
  
  export default getErrorMessage;

  
      
      
      
      
      
      