import { useNavigate, useLocation } from 'react-router-dom';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase'; // Update the path if necessary

// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

export const useGoogleAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoogleAuth = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });

    try {
      const result = await signInWithPopup(auth, provider);
      const email = result.user.email;
      const token = await result.user.getIdToken();

      // Send the user's email to your Django backend
      await checkOrCreateUser(email, token);
      
      // Determine where to redirect after Google Auth sign-in
      const redirectTo = location.state?.from?.pathname || '/'; // Use stored location or default to home
      const redirectSearch = location.state?.from?.search || ''; // Preserve any query params

      navigate(`${redirectTo}${redirectSearch}`); // Redirect to the previous page
    } catch (error) {
      console.error('Google Auth Error:', error);
      // Handle error (you can set an error state here and return it)
    }
  };

  const checkOrCreateUser = async (email, token) => {
    try {
      
      // Fetch CSRF token from your backend
      const csrfResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get_csrf_token/`, {
        method: 'GET',
        credentials: 'include',
      });
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;

      // Send email, Firebase token, and CSRF token to the backend
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/check_or_create_user/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include Firebase ID token
          'X-CSRFToken': csrfToken,  // Include CSRF token
        },
        credentials: 'include',
        body: JSON.stringify({ email: email, token: token }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to check/create user');
      }
      // Handle successful response
    } catch (error) {
      console.error('Error communicating with the server:', error);
      // Handle error
    }
  };

  return { handleGoogleAuth };
};
