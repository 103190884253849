import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CoachList from '../components/CoachList';
import NotFoundPage from './NotFoundPage';
import ErrorMessagePage from './ErrorMessagePage';
import { VALID_PRODUCTS } from '../constants'; 

import { Container, Typography, CircularProgress, Box } from '@mui/material';

const CoachListPage = () => {
    const location = useLocation();
    const state = location.state || {};
    const queryParams = new URLSearchParams(location.search);
    const product = state.productTitle || queryParams.get('product') || '';
    const role = state.role || queryParams.get('role') || '';
    const company = state.company || queryParams.get('company') || '';
    const [coaches, setCoaches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // Scroll to the top of the page on component mount
    useEffect(() => {
        if (!product || !VALID_PRODUCTS.includes(product)) {
            setError(true);
            setErrorMessage("There is an issue with the 'product' parameter: either it is missing, incorrect, or the provided value is not valid.");
            setLoading(false);
            return;
        } else {
            window.scrollTo(0, 0);
        }
    }, [product]);

    useEffect(() => {
        const fetchCoaches = async () => {
            try {
                const query = new URLSearchParams({ product, role, company }).toString();
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/coaches/?${query}`, {
                    method: 'GET',
                });
                if (!response.ok) {
                    setError(true);
                }
                const data = await response.json();
                setCoaches(data);
            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchCoaches();
        
    }, [product, role, company ]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        if (errorMessage) {
          return <ErrorMessagePage message={errorMessage} />; // Use the new ErrorMessagePage component
        } else {
          return <NotFoundPage />; // Fallback to NotFoundPage if no specific error message is set
        }
      }

    // if (coaches.length === 0) {
    //     return <Typography sx={{ textAlign: 'center', mt: 4 }}>No relevant coaches found.</Typography>;
    // }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <CoachList coaches={coaches} product={product} role={role} company={company} />
        </Container>
    );
};

export default CoachListPage;
