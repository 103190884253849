// CoachBookingPage.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CircularProgress from '@mui/material/CircularProgress';
import { add, format, parseISO } from 'date-fns';
import { convertTimeTo12HourFormat, getTimezoneAbbreviation } from '../utils/timeUtils';
import '../styles/CoachBookingPage.css'; 
import NotFoundPage from './NotFoundPage';
import ErrorMessagePage from './ErrorMessagePage';
import { VALID_PRODUCTS, VALID_PRODUCT_TYPES } from '../constants';

const CoachBookingPage = () => {
  const { unique_username } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const state = location.state || {};

  const productType = state.productType || queryParams.get('productType');
  const product = state.product || queryParams.get('product');
  const targetRole = state.role || queryParams.get('role');
  const targetCompany = state.company || queryParams.get('company');
  const coachIdFromState = state.coachId;

  const [coachId, setCoachId] = useState(coachIdFromState);
  const [fetchingId, setFetchingId] = useState(!coachIdFromState);

  const [selectedDate, setSelectedDate] = useState(state.selectedDate || null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(state.selectedTimeSlot || null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [timeZone, setTimeZone] = useState(state.timeZone || ''); // timeZone and userTimeZone are the same thing. timeZone stores formatted version of userTimeZone. E.g. PST for America/Los_Angeles
  const [userTimeZone, setUserTimeZone] = useState(state.userTimeZone || ''); // timeZone and userTimeZone are the same thing. userTimeZone stores original version of user time zone. E.g. America/Los_Angeles.
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const currentDate = new Date();
  const maxDate = add(currentDate, { days: 60 });

  // Fetch the coach ID using the unique username
  const fetchCoachId = async () => {
    setFetchingId(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/coaches/${unique_username}/id/`);
      if (!response.ok) {
        setError(true);
        return;
      }
      const data = await response.json();
      setCoachId(data.id);
    } catch (error) {
      console.error("Error fetching coach ID:", error);
      setError(true);
    } finally {
      setFetchingId(false);
    }
  };

  useEffect(() => {
    if (!coachId) {
      fetchCoachId();
    }
  }, [unique_username, coachId]);

  // Validate the state before using it
  useEffect(() => {
    if (!fetchingId && (!productType || !product || !coachId)) {
      setLoading(false);
      setError(true); // Set error state to true if state is missing
      return;
    }

    if (!VALID_PRODUCT_TYPES.includes(productType)) {
      setError(true);
      setErrorMessage("There is an issue with the 'productType' parameter: either it is missing, incorrect, or the provided value is not valid.");
      setLoading(false);
      return;
    }

    if (!VALID_PRODUCTS.includes(product)) {
      setError(true);
      setErrorMessage("There is an issue with the 'product' parameter: either it is missing, incorrect, or the provided value is not valid.");
      setLoading(false);
      return;
    }

    if (!fetchingId) {
      setLoading(true);

      let utz;

      try {
        utz = Intl.DateTimeFormat().resolvedOptions().timeZone || "America/Los_Angeles";
      } catch (error) {
        // In case of any unexpected error, default to America/Los_Angeles
        utz = "America/Los_Angeles";
      }
      setUserTimeZone(utz);

      const fetchCoachAvailability = async () => {
        // console.log("Fetching coach availability...");
        setLoading(true); // Set loading state to true before fetching
        try {
          // const response = await fetch(`http://127.0.0.1:8000/api/v1/coaches/${coachId}/availability?product=${product}&product_type=${productType}&user_time_zone=${encodeURIComponent(utz)}`);
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/coaches/${coachId}/availability?product=${product}&product_type=${productType}&user_time_zone=${encodeURIComponent(utz)}`);
          if (!response.ok) {
            throw new Error('Failed to fetch availability');
          }
          const data = await response.json();
          // console.log("Availability data fetched:", data.availability_slots_in_user_time_zone);
          const slots = data.availability_slots_in_user_time_zone || [];
          // console.log("Received slots from backend:", slots);
           // Use `parseISO` to correctly handle date strings
           const firstSlotDate = parseISO(slots[0].date); 
          //  console.log("First slot date (parsed):", firstSlotDate);
          setAvailableSlots(slots);
          // Convert the timezone identifier to an abbreviation
          const tzAbbreviation = getTimezoneAbbreviation(data.user_time_zone || ''); // delete this line if not using abbreviation; use line with Code: ABC instead.
          // Update the timezone state with the abbreviation
          setTimeZone(tzAbbreviation); // delete this line if not using abbreviation; use line with Code: ABC instead.
          // setTimeZone(data.time_zone || ''); // Code: ABC

          // Find the first available date and set it as selected
          if (slots.length > 0 && !selectedDate) {
            setSelectedDate(firstSlotDate);  // Set using parsed date
            // console.log("First available date set:", firstSlotDate);
          }

          if (selectedDate) {
            // console.log("Selected date:", selectedDate);
            // Find the correct slot for the selected date
            const matchingSlot = slots.find(slot => {
              return (
                parseISO(slot.date).toDateString() === selectedDate.toDateString() &&
                slot.start_time === selectedTimeSlot?.start_time && // Check if the start times match
                slot.end_time === selectedTimeSlot?.end_time // Check if the end times match
              );
            });
  
            if (matchingSlot) {
              // console.log("Matching slot found:", matchingSlot);
              setSelectedTimeSlot(matchingSlot);
            } 
          }
          
        } catch (error) {
          console.error("Error fetching coach availability:", error);
          setError(true); // Set error state to true if there's an error during fetching
        } finally {
          setLoading(false);
        }
      };
      
      if (coachId) {
        fetchCoachAvailability();
      }
    }
  }, [coachId, product, productType, fetchingId]);


  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTimeSlot(null); // Clear the selected time slot when changing the date
  };

  const handleTimeSlotSelect = (slot) => {
    setSelectedTimeSlot(slot);
  };

  const handleConfirmBooking = () => {
    if (!selectedTimeSlot) {
      alert('Please select a time slot.');
      return;
    }

    // Here, pass the necessary booking details to your API or the next page
    const queryParams = new URLSearchParams({
      productType,
      product,
      role: targetRole || '',
      company: targetCompany || '',
      selectedDate: selectedDate.toISOString(),
      startTime: selectedTimeSlot.start_time,
      endTime: selectedTimeSlot.end_time,
      timeZone,
      userTimeZone,
    }).toString();

    const stateToPass = { 
      coachId, 
      unique_username, 
      selectedDate, 
      selectedTimeSlot, 
      productType, 
      product,
      timeZone,
      userTimeZone,
      targetRole,
      targetCompany 
    };
    
    navigate(`/coaches/${unique_username}/booking/review-and-payment?${queryParams}`, { state: stateToPass });
    
  };

  if (loading || fetchingId) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </div>;
  }

  if (error) {
    if (errorMessage) {
      return <ErrorMessagePage message={errorMessage} />; // Use the new ErrorMessagePage component
    } else {
      return <NotFoundPage />; // Fallback to NotFoundPage if no specific error message is set
    }
  }

  const timeSlotsForSelectedDate = selectedDate ? availableSlots.filter(
    slot => parseISO(slot.date).toDateString() === selectedDate.toDateString()
  ) : [];

    return (
    <div className="booking-page">
      <h2 className="booking-heading">Select a Date & Time</h2>
      <div className="booking-container">
        <div className="calendar-and-timeslots-container">
          <div className="calendar">
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            className="react-calendar"
            tileClassName={({ date, view }) => {
              if (view === 'month') {
                const day = date.getDay();
                const dateString = format(date, 'yyyy-MM-dd');
                const isAvailable = availableSlots.some(slot => format(parseISO(slot.date), 'yyyy-MM-dd') === dateString);
                let classes = '';
            
                // Add 'weekend-date' class for weekends, but only if other conditions don't apply
                if (day === 0 || day === 6) { // 0 for Sunday, 6 for Saturday
                  classes += 'weekend-date ';
                }
            
                // Override weekend-date if specific availability styling is needed
                if (isAvailable) {
                  classes = 'available-date'; // This ensures available-date styling takes precedence
                } else if (!isAvailable && classes !== 'available-date') {
                  // Only add unavailable-date if it's not an available date
                  // This prevents weekend styling from overriding unavailable styling
                  classes += 'unavailable-date';
                }
            
                return classes.trim(); // Remove any trailing space
              }
            }}
            navigationLabel={({ date, label }) => format(date, 'MMM yyyy')}
          />
          <div className="time-zone-display">
            {timeZone}
          </div>
          </div>
          <div className="time-slots">
          {timeSlotsForSelectedDate.length > 0 ? (
            timeSlotsForSelectedDate.map((slot, index) => (
              <button
                key={index}
                className={`time-slot ${selectedTimeSlot === slot ? 'selected' : ''}`}
                onClick={() => handleTimeSlotSelect(slot)}
              >
                {convertTimeTo12HourFormat(slot.start_time)} {/* Use the conversion function */}
              </button>
            ))
          ) : (
            <p className="select-date-message">Select available date to view time slots.</p>
          )}
          </div>
        </div>
        <div className="button-container">
          {/* Summary Display */}
          {selectedDate && selectedTimeSlot && (
            <div className="selected-slot-summary">
              Selection: {format(selectedDate, 'do MMM yyyy')}, {convertTimeTo12HourFormat(selectedTimeSlot.start_time)} {timeZone}
            </div>
          )}
          <div className="back-button">
            <button onClick={() => navigate(`/coaches/${unique_username}?product=${product}&role=${targetRole}&company=${targetCompany}`)} className="back-btn">
              Back
            </button>
          </div>
          <div className="booking-button">
            <button onClick={handleConfirmBooking} disabled={!selectedTimeSlot} className="confirm-booking-btn">
              Next
            </button>
          </div>
        </div> {/* button container */}
      </div>
    </div>
  );
};
export default CoachBookingPage;