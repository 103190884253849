import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Import routing components
// import { ToastContainer } from 'react-toastify';
// import ProtectedRoute from './components/ProtectedRoute'; // Import your custom component

import { AuthProvider } from './AuthContext';
import Layout from './components/Layout';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import Register from './pages/Register'; // Import Register.jsx
import ForgotPassword from './pages/ForgotPassword'; 
import ResetPassword from './pages/ResetPassword'; 
import Account from './pages/Account';
import CoachListPage from './pages/CoachListPage'; 
import CoachProfilePage from './pages/CoachProfilePage';
import CoachBookingPage from './pages/CoachBookingPage';
import BookingReviewAndPaymentPage from './pages/BookingReviewAndPaymentPage';
import BookingConfirmationPage from './pages/BookingConfirmationPage';
import NotFoundPage from './pages/NotFoundPage';
import ProtectedRoute from './components/ProtectedRoute';

import './App.css'; // Import any necessary CSS files

// Import the GoogleAnalytics component
import GoogleAnalytics from './components/GoogleAnalytics'; 

function App() {

  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <GoogleAnalytics /> 
          <Routes>
            <Route path="/" element={<Layout><Home /></Layout>} />
            <Route path="/signin" element={<Layout><SignIn /></Layout>} />
            <Route path="/register" element={<Layout><Register /></Layout>} />
            <Route path="/forgot-password" element={<Layout><ForgotPassword /></Layout>} />
            <Route path="/reset-password" element={<Layout><ResetPassword /></Layout>} />
            <Route path="/account" element={<ProtectedRoute><Layout><Account /></Layout></ProtectedRoute>} />
            <Route path="/coaches" element={<Layout><CoachListPage /></Layout>} /> 
            <Route path="/coaches/:unique_username" element={<Layout><CoachProfilePage /></Layout>} />
            <Route path="/coaches/:unique_username/booking" element={<Layout><CoachBookingPage /></Layout>} />
            <Route path="/coaches/:unique_username/booking/review-and-payment" element={<Layout><BookingReviewAndPaymentPage /></Layout>} />
            <Route path="/coaches/:unique_username/booking/confirmation" element={<Layout><BookingConfirmationPage /></Layout>} />
            {/* <ProtectedRoute path="/protected-page" element={<Layout><ProtectedPage /></Layout>} /> */}
            {/* Catch-all route for undefined routes */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;